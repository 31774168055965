import React, { useEffect, useRef, useState } from 'react'
import styles from './LiveStream.module.css'
import AgoraRTC from "agora-rtc-sdk-ng";
import { useLocation, useNavigate } from 'react-router-dom';
import { agoraAppID, scheme } from 'constants/env';
import { useAuthReducer } from 'hooks/ReducerHooks/ReducerHooks';
import { firestoreDB } from 'services/Firebase/firebase';
import firestorekeys from 'constants/firestorekeys';
import { IoIosCloseCircle } from 'react-icons/io';
import { collection, deleteDoc, doc, onSnapshot, query, setDoc, updateDoc } from "firebase/firestore";
import { FaPauseCircle, FaPlayCircle, FaUser } from "react-icons/fa";
import { SiSlideshare } from "react-icons/si";
import { IoIosShareAlt } from "react-icons/io";
import { MdSupervisorAccount } from "react-icons/md";
import { FaHeart } from "react-icons/fa";
import { CgComment } from "react-icons/cg";
import { Spinner } from "react-bootstrap";
import avatar from 'assets/images/background_opinion.jpg'
import { useTranslation } from "react-i18next";
import LiveInvite from "../Componants/LiveInvite/LiveInvite";
import LiveShare from "../Componants/LiveShare/LiveShare";
import LiveComment from "../Componants/LiveComment/LiveComment";
import { useAgoraContext } from "contexts/AgoraContext";
import { agorakeys } from "constants/agorakeys";
import LiveHotes from "../Componants/LiveHotes/LiveHotes";
import { BiSolidMicrophone, BiSolidMicrophoneOff } from "react-icons/bi";
import background from 'assets/images/bg_clips.jpg'
import { RemoteUser } from 'agora-rtc-react';
import Broadcasters from './Broadcasters/Broadcasters';


const LiveStream = () => {


  const { t, i18n } = useTranslation()
  const { data: authContextState } = useAuthReducer()
  const navigate = useNavigate();
  const location = useLocation();
  const { live } = location?.state;

  // Agora live states
  const channelName = live?.live_channel_name
  const token = live?.live_code
  const [uid, setUid] = useState(0)

  const client = useRef()
  const localCameraTrack = useRef()
  const localMicrophoneTrack = useRef()

  const [audiences, setAudiences] = useState([])
  const [remoteUsers, setRemoteUsers] = useState([])
  const [broadcasters, setBroadcasters] = useState([]);
  const [broadcasterCurrent, setBroadcasterCurrent] = useState(null);

  const [joined, setJoined] = useState(false)
  const [onMute, setOnMute] = useState(false);
  const [onPause, setOnPause] = useState(false);

  // LIVE STATES
  const [isLiked, setIsLiked] = useState(false)
  const [likeCount, setLikeCount] = useState(0)
  const [commentCount, setCommentCount] = useState(0)
  const [audienceCount, setAudienceCount] = useState(0)
  const [requests, setRequests] = useState([])

  // MODAL STATE
  const [showInviteLive, setShowInviteLive] = useState(false)
  const [showShareLive, setShowShareLive] = useState(false)
  const [showHoteLive, setShowHoteLive] = useState(false)

  const handleShowShareLive = () => setShowShareLive(oldValue => !oldValue)
  const handleShowInviteLive = () => setShowInviteLive(oldValue => !oldValue)
  const handleShowHoteLive = () => setShowHoteLive(oldValue => !oldValue)


  // audience snapshot
  useEffect(() => {
    const q = query(
      collection(
        firestoreDB,
        scheme,
        firestorekeys.lives,
        firestorekeys.channels,
        channelName,
        firestorekeys.audiences
      ),
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
      }));
      setAudiences(data)

      setBroadcasterCurrent(data?.filter(item =>
        item?.live_is_broadcaster && item?.live_current_speaker
      )[0])

      setBroadcasters(data?.filter(item =>
        item?.live_is_broadcaster && !item?.live_current_speaker
      )?.sort((a, b) => b?.live_current_speaker - a?.live_current_speaker));

    });
    return () => {
      unsubscribe();
    }
  }, []);


  // Requests snapshot
  useEffect(() => {
    const q = query(
      collection(
        firestoreDB,
        scheme,
        firestorekeys.lives,
        firestorekeys.channels,
        channelName,
        firestorekeys.requests
      ),
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
      }));
      setRequests(data)
    });
    return () => {
      unsubscribe();
    }
  }, []);


  // Likes snapshot
  useEffect(() => {
    const q = query(
      collection(
        firestoreDB,
        scheme,
        firestorekeys.lives,
        firestorekeys.channels,
        channelName,
        firestorekeys.likes
      ),
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
      }));
      setIsLiked(data?.some(item => item?.id == authContextState?.user?.user_id))
      setLikeCount(data?.length)
    });
    return () => {
      unsubscribe();
    }
  }, []);



  // Audiences snapshot
  useEffect(() => {
    const q = query(
      collection(
        firestoreDB,
        scheme,
        firestorekeys.lives,
        firestorekeys.channels,
        channelName,
        firestorekeys.audiences
      ),
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
      }));
      setAudienceCount(data?.length)
    });
    return () => {
      unsubscribe();
    }
  }, []);


  // Get broadcaster
  useEffect(() => {
    setBroadcasterCurrent(audiences?.filter(item =>
      item?.live_is_broadcaster && item?.live_current_speaker
    )[0])

    setBroadcasters(audiences?.filter(item =>
      item?.live_is_broadcaster && !item?.live_current_speaker
    ));
  }, [audiences, remoteUsers, requests]);


  useEffect(() => {
    const initializeClient = async () => {
      const agoraClient = AgoraRTC.createClient({
        mode: "live",
        codec: "vp8",
        role: "host",
        // logConfig: {
        //   level: 2, // Suppresses all logs
        //   // Options:
        //   // 0: NONE
        //   // 1: CRITICAL
        //   // 2: ERROR
        //   // 3: WARNING
        //   // 4: INFO (default)
        //   // 5: DEBUG
        // },
      });

      client.current = agoraClient
      agoraClient.setClientRole(agorakeys.broadcasterRole)

      // Join channel
      if (!joined) {
        const user = await client.current.join(
          agoraAppID,
          channelName,
          token,
          uid || null
        )
        setUid(user)
        addAudience(user)
      }


      // Create media track
      const [localAudioTrack, localVideoTrack] = await AgoraRTC.createMicrophoneAndCameraTracks();
      localAudioTrack.setEnabled(true)
      localVideoTrack.setEnabled(true)

      localMicrophoneTrack.current = localAudioTrack
      localCameraTrack.current = localVideoTrack

      // Play local stream
      localVideoTrack.play("local-stream");


      // Event listener
      agoraClient.on("user-joined", async (user) => {
        setJoined(true)
      })

      agoraClient.on("user-published", async (user, mediaType) => {
        try {
          console.log("user-published:", user.uid);

          // Subscribe to the user
          await agoraClient.subscribe(user, mediaType);

          if (mediaType === "video") {
            const remoteVideoTrack = user.videoTrack;

            // Create and ensure the video container
            const containerId = `remote-stream-${user.uid}`;
            let videoContainer = document.getElementById(containerId);
            if (!videoContainer) {
              videoContainer = document.createElement("div");
              videoContainer.id = containerId;
              videoContainer.style.width = "100%";
              videoContainer.style.height = "200px";
              document.getElementById("remote-container").appendChild(videoContainer);
            }

            // Play the video track
            remoteVideoTrack.play(containerId);
            console.log("Playing remote video for user:", user.uid);
          }

          if (mediaType === "audio" || mediaType === "all") {
            const remoteAudioTrack = user.audioTrack;
            remoteAudioTrack.play();
          }

          setRemoteUsers((oldUsers) => {
            const exists = oldUsers.some((item) => item.uid === user.uid);
            return exists
              ? oldUsers
              : [...oldUsers, { uid: user.uid, videoTrack: user.videoTrack }];
          });
        } catch (error) {
          console.error("Error handling user-published:", error);
        }
      });

      agoraClient.on("user-unpublished", (user) => {
        console.log("user-unpublished:====================", remoteUsers);
        setRemoteUsers(oldValue =>
          oldValue.filter(item => item?.uid !== user.uid)
        )
      });

      agoraClient.on("user-left", (user) => {
        console.log("user-left:", user);
        setRemoteUsers(oldValue =>
          oldValue.filter(item => item?.uid !== user.uid)
        )
      });

      // Publish audio and video track
      await agoraClient.publish([localAudioTrack, localVideoTrack])
    }
    initializeClient()

    return () => leaveChannel()
  }, [])

  useEffect(() => {
    const getBroadcaster = () => {
      setBroadcasterCurrent(
        audiences?.filter(item =>
          item?.live_is_broadcaster && item?.live_current_speaker
        )[0]
      )

      setBroadcasters(
        audiences?.filter(item =>
          item?.live_is_broadcaster &&
          !item?.live_current_speaker
        )
      )
    }
    getBroadcaster()
  }, [remoteUsers, audiences, requests])


  const leaveChannel = async () => {
    // Stop local audio
    if (localMicrophoneTrack?.current) {
      localMicrophoneTrack?.current?.stop();
    }

    // Stop local video
    if (localCameraTrack?.current) {
      localCameraTrack?.current?.stop();
    }

    // Unpublish local audio and video track
    if (localMicrophoneTrack?.current && localCameraTrack?.current) {
      await client.current?.unpublish([localMicrophoneTrack.current, localCameraTrack.current]);
    }

    await client.current?.removeAllListeners();
    await client.current?.leave();

    setJoined(false);
    localMicrophoneTrack.current = null;
    localCameraTrack.current = null;
  }

  const handleFinishLive = async () => {
    // Finish live
    const refDoc = doc(
      firestoreDB,
      scheme,
      firestorekeys.lives,
      firestorekeys.channels,
      channelName
    )
    await updateDoc(refDoc, {
      live_finished: true
    }).then(() => {
      console.log('Live finished.');
      leaveChannel()
      navigate(-1)
    }).catch((error) => {
      console.error('Error live finished:', error);
    });
  }

  const addAudience = async (user) => {
    try {
      const collectionRef = collection(
        firestoreDB,
        scheme,
        firestorekeys.lives,
        firestorekeys.channels,
        channelName,
        firestorekeys.audiences
      );
      const docRef = doc(collectionRef, authContextState?.user?.user_id?.toString())
      await setDoc(docRef, {
        ...authContextState?.user,
        live_local_uid: 0,
        live_remote_uid: user,
        live_current_speaker: true,
        live_is_owner: true,
        live_is_broadcaster: true,
        live_on_pause: false,
        live_on_mute: false,
      }).then(() => {
        console.log('Live audience added.');
      }).catch((error) => {
        console.error('Error audience added:', error);
      });
    } catch (error) {
      console.error('Error audience added:', error);
    }
  }

  const handleRemoveAudience = async () => {
    try {
      const collectionRef = collection(
        firestoreDB,
        scheme,
        firestorekeys.lives,
        firestorekeys.channels,
        channelName,
        firestorekeys.audiences
      );

      const refDoc = doc(collectionRef, authContextState?.user?.user_id?.toString())

      await deleteDoc(refDoc).then(() => {
        console.log('Live remove audience.');
        handleEndLive()
      }).catch((error) => {
        console.error('Error remove audience:', error);
      });
    } catch (error) {
      console.error('Error remove audience:', error);
    }
  }


  const handleEndLive = async () => {
    try {
      const refDoc = doc(
        firestoreDB,
        scheme,
        firestorekeys.lives,
        firestorekeys.channels,
        channelName
      )

      await updateDoc(refDoc, { live_finished: true }).then(() => {
        console.log('Live finished.');
        navigate('/')
      }).catch((error) => {
        console.error('Error live finished:', error);
      });
    } catch (error) {
      console.error('Error live finished:', error);
    }
  }

  const handleLike = async () => {
    try {
      const collectionRef = collection(
        firestoreDB,
        scheme,
        firestorekeys.lives,
        firestorekeys.channels,
        channelName,
        firestorekeys.likes
      );

      const refDoc = doc(collectionRef, authContextState?.user?.user_id?.toString())

      if (isLiked) {
        await deleteDoc(refDoc).then(() => {
          console.log('Live like added.');
        }).catch((error) => {
          console.error('Error like added:', error);
        });
      } else {
        await setDoc(refDoc, authContextState?.user).then(() => {
          console.log('Live like added.');
        }).catch((error) => {
          console.error('Error like added:', error);
        });
      }
    } catch (error) {
      console.error('Error like added:', error);
    }
  }


  const handleOnMute = async () => {
    if (localMicrophoneTrack?.current) {
      if (onMute) {
        localMicrophoneTrack?.current?.setMuted(false);
        setOnMute(false)

        const docRef = doc(
          firestoreDB,
          scheme,
          firestorekeys.lives,
          firestorekeys.channels,
          channelName
        )
        await updateDoc(docRef, {
          live_on_mute: false,
        })
      } else {
        localMicrophoneTrack?.current?.setMuted(true);
        setOnMute(true)

        const docRef = doc(
          firestoreDB,
          scheme,
          firestorekeys.lives,
          firestorekeys.channels,
          channelName
        )
        await updateDoc(docRef, {
          live_on_mute: true,
        })
      }
    }
  };


  const handleOnPause = async () => {
    if (localMicrophoneTrack?.current && localCameraTrack?.current) {
      if (onPause) {
        localMicrophoneTrack?.current?.setMuted(false);
        localCameraTrack?.current?.setMuted(false);
        setOnMute(false)
        setOnPause(false)

        const docRef = doc(
          firestoreDB,
          scheme,
          firestorekeys.lives,
          firestorekeys.channels,
          channelName
        )
        await updateDoc(docRef, {
          live_on_pause: false,
        })
      } else {
        localMicrophoneTrack?.current?.setMuted(true);
        localCameraTrack?.current?.setMuted(true);
        setOnMute(true)
        setOnPause(true)

        const docRef = doc(
          firestoreDB,
          scheme,
          firestorekeys.lives,
          firestorekeys.channels,
          channelName
        )
        await updateDoc(docRef, {
          live_on_pause: true,
        })
      }
    }
  };


  return (
    <div className={`${styles.page} h-screen overflow-hidden bg-black w-3/3`}>
      <div className={`${styles.container} w-full h-full relative items-baseline`}>

        {/* Local and remote video strean */}
        <div
          style={{
            backgroundImage: `url(${live?.live_thumbnail || background})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          }}
          className='flex justify-between w-full h-full'>

          {/* Local stream */}
          <div
            id="local-stream"
            style={broadcasters?.length == 0 ?
              {
                width: '100%',
                height: '100%',
                backgroundColor: 'black',
              }
              :
              {
                width: '65%',
                height: '60%',
                marginTop: '20%',
                backgroundColor: 'black',
                border: '1px solid #000000'
              }
            }
          />

          {/* Remote user stream */}
          <div id="remote-container"
            style={{
              width: '35%',
              marginTop: '20%',
              height: '60%',
              display: broadcasters?.length == 0 ? 'none' : 'block',
              overflowY: 'auto'
            }}>
            {broadcasters?.map((user, index) => (
              <div key={index}>
                <Broadcasters
                  user={user}
                  live={live}
                />
              </div>
              // <div
              //   id={`remote-stream-${user?.live_remote_uid}`}
              //   key={index}
              //   className="bg-black w-full h-52"
              //   style={{ border: '1px solid #000000' }}>
              // </div>
            ))}
          </div>
        </div>

        <div>
          {/* HEADER */}
          <div className="w-full top-0 absolute flex justify-between items-center p-3" >

            <div className="flex items-center">
              <div className="bg-red-500 text-white px-3 rounded-full">
                Live
              </div>
              <div className="flex items-center mx-2 px-3 text-white bg-neutral-900 rounded-full">
                <FaUser />
                <span className="ms-1 font-bold">
                  {audienceCount || 0}
                </span>
              </div>
            </div>

            {/* Button options broadcast */}
            <div>
              <button
                onClick={handleOnMute}
                className="cursor-pointer p-2 me-4 rounded-full"
                style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
                {onMute
                  ? <BiSolidMicrophoneOff color="white" size={32} />
                  : <BiSolidMicrophone color="white" size={32} />
                }
              </button>

              <button
                onClick={handleOnPause}
                className="cursor-pointer p-2 me-4 rounded-full"
                style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
                {onPause
                  ? <FaPlayCircle color="white" size={32} />
                  : <FaPauseCircle color="white" size={32} />
                }
              </button>

              <button
                onClick={handleFinishLive}
                className="cursor-pointer p-2 rounded-full"
                style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
                <IoIosCloseCircle color="white" size={32} />
              </button>
            </div>

          </div>

          <div className="flex items-end justify-between w-full absolute bottom-32 lg:bottom-20 p-3 mt-auto">

            <div className="w-96 text-white">
              <LiveComment
                channelName={channelName}
                onChangeCommentCount={setCommentCount}
              />
            </div>

            <div className="me-2">
              <button onClick={handleLike}>
                <FaHeart color={likeCount != 0 ? 'red' : "white"} size={32} className="mx-auto" />
                <p className="text-white text-center text-sm font-bold">
                  {likeCount || 0}
                </p>
              </button>

              <div className="mt-3">
                <CgComment color="white" size={32} className="mx-auto" />
                <p className="text-white text-center text-sm font-bold">
                  {commentCount || 0}
                </p>
              </div>
            </div>

          </div>

          {/* BOTTOM */}
          <div className="flex items-center justify-between w-full absolute bottom-16 lg:bottom-0 p-3 mt-auto">

            {/* USER */}
            <div className="flex p-1 items-center bg-neutral-900 w-72 rounded-full me-3">
              <img src={authContextState?.user?.profile?.prof_picture || avatar} alt="" className="w-10 h-10 rounded-full me-1" />
              <div className="ps-2 pe-4">
                <h3 className="text-white font-bold">{authContextState?.user?.user_surname} {authContextState?.user?.user_name}</h3>
                <span className="text-xs text-white ">@{authContextState?.user?.user_username}</span>
              </div>
            </div>

            <div className="flex items-center justify-end w-96">
              <button onClick={handleShowHoteLive} className="me-8">
                <SiSlideshare color="white" size={32} className="mx-auto" />
                <p className="text-white text-center text-sm">
                  <div className="flex items-center">
                    {requests?.length > 0 &&
                      <div className="ms-auto w-5 top-0 me-1 right-0 text-white bg-red-500 rounded-full">
                        <span className="m-1">{requests?.filter(item => !item?.live_request_status)?.length < 10 ? requests?.length : '9+'}</span>
                      </div>
                    }
                    <span>
                      {i18n.language == 'en'
                        ? 'Hosts'
                        : 'Hôtes'
                      }
                    </span>
                  </div>
                </p>
              </button>

              <button onClick={handleShowInviteLive} className="me-8">
                <MdSupervisorAccount color="white" size={32} className="mx-auto" />
                <p className="text-white text-center text-sm">
                  {i18n.language == 'en'
                    ? 'Invite'
                    : 'Inviter'
                  }
                </p>
              </button>

              <button onClick={handleShowShareLive}>
                <IoIosShareAlt color="white" size={32} className="mx-auto" />
                <p className="text-white text-center text-sm">
                  {i18n.language == 'en'
                    ? 'Share'
                    : 'Partager'
                  }
                </p>
              </button>
            </div>

          </div>

        </div>
      </div>

      {/* INVITE USER */}
      <LiveInvite
        live={live}
        visible={showInviteLive}
        onChangeVisible={handleShowInviteLive}
      />

      {/* SHARE LIVE */}
      <LiveShare
        live={live}
        visible={showShareLive}
        onChangeVisible={handleShowShareLive}
      />

      <LiveHotes
        live={live}
        requests={requests}
        visible={showHoteLive}
        onChangeVisible={handleShowHoteLive}
      />

    </div>
  )
}

export default LiveStream




// import React, { useEffect, useRef, useState } from "react";
// import { useParams, useNavigate, useLocation, Link } from "react-router-dom";
// import styles from './LiveStream.module.css'
// import {
//   AgoraRTCProvider,
//   LocalUser,
//   LocalVideoTrack,
//   RemoteUser,
//   useClientEvent,
//   useIsConnected,
//   useJoin,
//   uselocalVideoTrack,
//   uselocalAudioTrack,
//   usePublish,
//   useRemoteAudioTracks,
//   useRemoteUsers,
//   useRemoteVideoTracks,
//   useRTCClient,
// } from "agora-rtc-react";
// import AgoraRTC from "agora-rtc-sdk-ng";
// import { agoraAppID } from "constants/env";
// import { collection, deleteDoc, doc, onSnapshot, query, setDoc, updateDoc } from "firebase/firestore";
// import firestorekeys from "constants/firestorekeys";
// import { firestoreDB } from "services/Firebase/firebase";
// import { FaUser } from "react-icons/fa";
// import { useAccountReducer } from "hooks/ReducerHooks/ReducerHooks";
// import { useAuthReducer } from "hooks/ReducerHooks/ReducerHooks";
// import { IoIosCloseCircle } from "react-icons/io";
// import { SiSlideshare } from "react-icons/si";
// import { IoIosShareAlt } from "react-icons/io";
// import { MdSupervisorAccount } from "react-icons/md";
// import { FaHeart } from "react-icons/fa";
// import { CgComment } from "react-icons/cg";
// import { Spinner } from "react-bootstrap";
// import avatar from 'assets/images/background_opinion.jpg'
// import { useTranslation } from "react-i18next";
// import LiveInvite from "../Componants/LiveInvite/LiveInvite";
// import LiveShare from "../Componants/LiveShare/LiveShare";
// import LiveComment from "../Componants/LiveComment/LiveComment";
// import { scheme } from "constants/env";
// import { useAgoraContext } from "contexts/AgoraContext";
// import { agorakeys } from "constants/agorakeys";
// import LiveHotes from "../Componants/LiveHotes/LiveHotes";
// import { BiSolidMicrophone, BiSolidMicrophoneOff } from "react-icons/bi";


// const LiveStream = () => {

//   const { t, i18n } = useTranslation()
//   const { data: authContextState } = useAuthReducer()
//   const navigate = useNavigate();
//   const location = useLocation();
//   const { live } = location?.state;

//   // AGORA CLIENT
//   const agoraEngineRef = useRef(null)
//   const client = useRef(null)
//   const [localMicrophoneTrack, setLocalMicrophoneTrack] = useState(null);
//   const [localCameraTrack, setLocalCameraTrack] = useState(null);

//   const [appID, setAppID] = useState(agoraAppID)
//   const [uid, setUid] = useState(0)
//   const channelName = live?.live_channel_name
//   const token = live?.live_code


//   // LOCAL USER
//   const [started, setStarted] = useState(false);
//   const [joined, setJoined] = useState(false);
//   const [isMuted, setIsMuted] = useState(false);
//   const [isPaused, setIsPaused] = useState(false);


//   // LIVE STATES
//   const [isLiked, setIsLiked] = useState(false)
//   const [likeCount, setLikeCount] = useState(0)
//   const [commentCount, setCommentCount] = useState(0)
//   const [audienceCount, setAudienceCount] = useState(0)
//   const [requests, setRequests] = useState([])

//   // MODAL STATE
//   const [showInviteLive, setShowInviteLive] = useState(false)
//   const [showShareLive, setShowShareLive] = useState(false)
//   const [showHoteLive, setShowHoteLive] = useState(false)

//   const handleShowShareLive = () => setShowShareLive(oldValue => !oldValue)
//   const handleShowInviteLive = () => setShowInviteLive(oldValue => !oldValue)
//   const handleShowHoteLive = () => setShowHoteLive(oldValue => !oldValue)


//   // Requests snapshot
//   useEffect(() => {
//     const q = query(
//       collection(
//         firestoreDB,
//         scheme,
//         firestorekeys.lives,
//         firestorekeys.channels,
//         channelName,
//         firestorekeys.requests
//       ),
//     );
//     const unsubscribe = onSnapshot(q, (querySnapshot) => {
//       const data = querySnapshot.docs.map((doc) => ({
//         id: doc.id,
//         ...doc.data()
//       }));
//       setRequests(data)
//     });
//     return () => {
//       unsubscribe();
//     }
//   }, []);


//   // Likes snapshot
//   useEffect(() => {
//     const q = query(
//       collection(
//         firestoreDB,
//         scheme,
//         firestorekeys.lives,
//         firestorekeys.channels,
//         channelName,
//         firestorekeys.likes
//       ),
//     );
//     const unsubscribe = onSnapshot(q, (querySnapshot) => {
//       const data = querySnapshot.docs.map((doc) => ({
//         id: doc.id,
//         ...doc.data()
//       }));
//       setIsLiked(data?.some(item => item?.id == authContextState?.user?.user_id))
//       setLikeCount(data?.length)
//     });
//     return () => {
//       unsubscribe();
//     }
//   }, []);



//   // Audiences snapshot
//   useEffect(() => {
//     const q = query(
//       collection(
//         firestoreDB,
//         scheme,
//         firestorekeys.lives,
//         firestorekeys.channels,
//         channelName,
//         firestorekeys.audiences
//       ),
//     );
//     const unsubscribe = onSnapshot(q, (querySnapshot) => {
//       const data = querySnapshot.docs.map((doc) => ({
//         id: doc.id,
//         ...doc.data()
//       }));
//       setAudienceCount(data?.length)
//     });
//     return () => {
//       unsubscribe();
//     }
//   }, []);


//   useEffect(() => {
//     if (joined) {
//       // Add audience
//       handleAddAudience(authContextState?.user?.user_id?.toString(), {
//         ...authContextState?.user,
//         live_remote_uid: authContextState?.user?.user_id
//       })
//     }
//   }, [joined])

//   let rtc = {
//     localAudioTrack: null,
//     localVideoTrack: null,
//     client: null, // AgoraRTC client object
//   };

//   let options = {
//     appId: appID,  // Your app ID
//     channel: channelName,       // Channel name
//     token: token, // Temp token
//     uid: uid,           // User ID
//   };

//   // Initialize the AgoraRTC client
//   useEffect(() => {
//     const initializeAgoraClient = async () => {
//       try {
//         rtc.client = AgoraRTC.createClient({ mode: "live", codec: "vp8", role: "host" });
//         joinAsHost()


//         // agoraClient.setClientRole(agorakeys.broadcasterRole)
//         // agoraEngineRef.current = agoraClient
//         // console.log('Response agora init ===================:', agoraClient)

//         // Join channel 
//         // await agoraClient.join(appID, channelName, token || null, uid || null,);




//         // Event listener
//         rtc.client.on("user-joined", (user) => {
//           console.log("user-joined")
//         })

//         rtc.client.on("user-published", async (user, mediaType) => {
//           console.log("user-published========================")
//         });

//         rtc.client.on("user-unpublished", (user) => {
//           console.log("user-unpublished")
//         });

//         // createAudioAndVideoTrack()
//       } catch (error) {
//         console.error('Error initialize Agora Client:', error);
//       }
//     }
//     initializeAgoraClient()

//     // return () => {
//     //   // Remove all listeners on cleanup
//     //   agoraEngineRef.current.removeAllListeners();
//     //   leaveChannelLive()
//     // };
//   }, [channelName])

//   async function joinAsHost() {
//     await rtc.client.join(options.appId, options.channel, options.token, options.uid);

//     // A host can both publish tracks and subscribe to tracks
//     rtc.client.setClientRole("host");
//     await createAndPublishLocalTracks();
//     displayLocalVideo();
//     disableJoinButtons();
//     console.log("Host joined and published tracks.");
// }


// // Create and publish local tracks
// async function createAndPublishLocalTracks() {
//   rtc.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
//   rtc.localVideoTrack = await AgoraRTC.createCameraVideoTrack();
//   await rtc.client.publish([rtc.localAudioTrack, rtc.localVideoTrack]);
// }

// function displayRemoteVideo(user) {
//   const remotePlayerContainer = document.createElement("div");
//   remotePlayerContainer.id = user.uid.toString();
//   remotePlayerContainer.textContent = `Remote user ${user.uid}`;
//   remotePlayerContainer.style.width = "640px";
//   remotePlayerContainer.style.height = "480px";
//   document.body.append(remotePlayerContainer);
//   user.videoTrack.play(remotePlayerContainer);
// }


// // const createAudioAndVideoTrack = async () => {
// //   try {
// //     // Unpublish and clean up old tracks if they exist
// //     if (localMicrophoneTrack || localCameraTrack) {
// //       if (localMicrophoneTrack) {
// //         localMicrophoneTrack.stop();
// //         localMicrophoneTrack.close();
// //       }
// //       if (localCameraTrack) {
// //         localCameraTrack.stop();
// //         localCameraTrack.close();
// //       }
// //       await agoraEngineRef.current.unpublish([localMicrophoneTrack, localCameraTrack]);
// //     }

// //     // Create new audio and video tracks
// //     const [newAudioTrack, newVideoTrack] = await AgoraRTC.createMicrophoneAndCameraTracks();
// //     newAudioTrack.setEnabled(true);
// //     newVideoTrack.setEnabled(true);

// //     setLocalMicrophoneTrack(newAudioTrack);
// //     setLocalCameraTrack(newVideoTrack);

// //     // Play the local video stream in the specified container
// //     newVideoTrack.play("local-stream");

// //     // Publish the new tracks
// //     await agoraEngineRef.current.publish([newAudioTrack, newVideoTrack]);
// //     console.log('Published new audio and video tracks successfully');
// //   } catch (error) {
// //     console.error('Error creating audio and video tracks:', error);
// //   }
// // };


// // // Leave the channel and clean up
// // async function leaveChannelLive() {
// //   agoraEngineRef.localAudioTrack.close(); // Stop local audio
// //   await agoraEngineRef.client.leave();    // Leave the channel
// //   console.log("Left the channel.");
// // }



// // AGORA INIT
// // useEffect(() => {
// //   const agoaraInit = async () => {
// //     try {
// //       if (joined) return

// //       const agoraClient = AgoraRTC.createClient({ mode: "live", codec: "vp8" });
// //       client.current = agoraClient
// //       agoraClient.setClientRole(agorakeys.broadcasterRole)
// //       console.log('Response agora init:', agoraClient)

// //       // Join channel
// //       await agoraClient.join(
// //         appID,
// //         channelName,
// //         token || null,
// //         uid || null,
// //       );


// //       const [localAudioTrack, localVideoTrack] = await AgoraRTC.createMicrophoneAndCameraTracks();

// //       console.log('======', localVideoTrack)
// //       localAudioTrack.setEnabled(true)
// //       localVideoTrack.setEnabled(true)

// //       localAudioTrack.current = localAudioTrack
// //       localVideoTrack.current = localVideoTrack

// //       localVideoTrack.play("local-stream");


// //       // EVENT LISTENER
// //       let localUserUid = null

// //       agoraClient.on("user-joined", (user) => {
// //         if (localUserUid) return
// //         console.log("user-joined:", user);

// //         setUid(user.uid)
// //         localUserUid = user.uid

// //         setJoined(true);
// //       })

// //       agoraClient.on("user-published", async (user, mediaType) => {
// //         if (user.uid == localUserUid) return

// //         // Subscribe to a remote user
// //         await agoraClient.subscribe(user, mediaType);
// //         console.log("subscribe success", user.uid);

// //         if (mediaType === "video" || mediaType === "all") {
// //           // Get `RemoteVideoTrack` in the `user` object.
// //           const remoteVideoTrack = user.videoTrack;
// //           console.log(remoteVideoTrack);

// //           user.videoTrack.play(`${user.uid}`);
// //         }

// //         if (mediaType === "audio" || mediaType === "all") {
// //           // Get `RemoteAudioTrack` in the `user` object.
// //           const remoteAudioTrack = user.audioTrack;
// //           // Play the audio track. Do not need to pass any DOM element
// //           remoteAudioTrack.play();
// //         }
// //       });

// //       agoraClient.on("user-unpublished", (user) => {
// //         console.log("user-unpublished:", user);
// //       });

// //       // PUBLISH
// //       await client.current.publish([localAudioTrack, localVideoTrack])

// //     } catch (error) {
// //       console.error('Error init agora', error);
// //     }
// //   }
// //   agoaraInit()

// //   return () => {
// //     // leaveChannel()
// //   };
// // }, []);



// const leaveChannel = async () => {
//   // if (!client.current) return;
//   // setJoined(false)

//   // localAudioTrack.current && localAudioTrack.current.stop();
//   // localVideoTrack.current && localVideoTrack.current.stop();
//   // await client.current.unpublish([localAudioTrack.current, localVideoTrack.current]);


//   // localAudioTrack.current && localAudioTrack.current.close();
//   // localVideoTrack.current && localVideoTrack.current.close();

//   // await client.current.removeAllListeners();
//   // await client.current.leave();

//   // setJoined(false);
//   // localAudioTrack.current = null;
//   // localVideoTrack.current = null;

//   handleRemoveAudience()
//   handleEndLive()

//   navigate('/')
// };




// const handleRemoveAudience = async () => {
//   try {
//     const collectionRef = collection(
//       firestoreDB,
//       scheme,
//       firestorekeys.lives,
//       firestorekeys.channels,
//       channelName,
//       firestorekeys.audiences
//     );

//     const refDoc = doc(collectionRef, authContextState?.user?.user_id?.toString())

//     await deleteDoc(refDoc).then(() => {
//       console.log('Live remove audience.');
//       handleEndLive()
//     }).catch((error) => {
//       console.error('Error remove audience:', error);
//     });
//   } catch (error) {
//     console.error('Error remove audience:', error);
//   }
// }


// const handleEndLive = async () => {
//   try {
//     const refDoc = doc(
//       firestoreDB,
//       scheme,
//       firestorekeys.lives,
//       firestorekeys.channels,
//       channelName
//     )

//     await updateDoc(refDoc, { live_finished: true }).then(() => {
//       console.log('Live finished.');
//       navigate('/')
//     }).catch((error) => {
//       console.error('Error live finished:', error);
//     });
//   } catch (error) {
//     console.error('Error live finished:', error);
//   }
// }



// const handleLike = async () => {
//   try {
//     const collectionRef = collection(
//       firestoreDB,
//       scheme,
//       firestorekeys.lives,
//       firestorekeys.channels,
//       channelName,
//       firestorekeys.likes
//     );

//     const refDoc = doc(collectionRef, authContextState?.user?.user_id?.toString())

//     if (isLiked) {
//       await deleteDoc(refDoc).then(() => {
//         console.log('Live like added.');
//       }).catch((error) => {
//         console.error('Error like added:', error);
//       });
//     } else {
//       await setDoc(refDoc, authContextState?.user).then(() => {
//         console.log('Live like added.');
//       }).catch((error) => {
//         console.error('Error like added:', error);
//       });
//     }
//   } catch (error) {
//     console.error('Error like added:', error);
//   }
// }


// const handleAddAudience = async (id, data) => {
//   try {
//     const collectionRef = collection(
//       firestoreDB,
//       scheme,
//       firestorekeys.lives,
//       firestorekeys.channels,
//       channelName,
//       firestorekeys.audiences
//     );

//     const refDoc = doc(collectionRef, id)

//     await setDoc(refDoc, data).then(() => {
//       console.log('Live audience added.');
//     }).catch((error) => {
//       console.error('Error audience added:', error);
//     });
//   } catch (error) {
//     console.error('Error audience added:', error);
//   }
// }

// const onMute = () => {
//   // console.log('localAudioTrack ==========', localAudioTrack?.current)
//   // if (localAudioTrack?.current) {
//   //   if (isMuted) {
//   //     localAudioTrack?.current?.setMuted(true);
//   //   } else {
//   //     localAudioTrack?.current?.setMuted(false);
//   //   }
//   //   setIsMuted(!isMuted);
//   // }
// };



// // if (!joined) {
// //   return (
// //     <div className="flex justify-center items-center h-screen bg-black text-center text-white">
// //       <div>
// //         <Spinner
// //           size="sm"
// //           role="status"
// //           aria-hidden="true"
// //           animation="border"
// //           variant={'secondary'}
// //         />
// //         <p>{' '} Connexion...</p>
// //       </div>
// //     </div>
// //   );
// // }


// return (
//   <div className={`${styles.page} h-screen overflow-hidden bg-black w-3/3`}>

//     <div className={`${styles.container} w-full h-full relative items-baseline`}>

//       {/* <div className={`${styles.localUser} h-screen`} >
//           <LocalVideoTrack
//             videoTrack={localVideoTrack}
//             cameraOn
//             micOn
//             playAudio
//             playVideo
//           />
//         </div> */}


//       <div id="local-stream" className={`local-stream w-full mx-auto h-screen bg-black`}>

//       </div>

//       <div>
//         {/* HEADER */}
//         <div className="w-full top-0 absolute flex justify-between items-center p-3" >

//           <div className="flex items-center">
//             <div className="bg-red-500 text-white px-3 rounded-full">
//               Live
//             </div>
//             <div className="flex items-center mx-2 px-3 text-white bg-neutral-900 rounded-full">
//               <FaUser />
//               <span className="ms-1 font-bold">
//                 {audienceCount || 0}
//               </span>
//             </div>
//           </div>

//           <div>

//             <button
//               onClick={onMute}
//               className="cursor-pointer ms-3">
//               {isPaused
//                 ? <BiSolidMicrophoneOff color="white" size={32} />
//                 : <BiSolidMicrophone color="white" size={32} />
//               }
//             </button>

//             {/*    
//               <button onClick={leaveChannel} className="cursor-pointer ms-3">
//                 <IoIosCloseCircle color="white" size={32} />
//               </button>
//               */}

//             <button onClick={leaveChannel} className="cursor-pointer ms-3">
//               <IoIosCloseCircle color="white" size={32} />
//             </button>
//           </div>


//         </div>

//         <div className="flex items-end justify-between w-full absolute bottom-32 lg:bottom-20 p-3 mt-auto">

//           <div className="w-96 text-white">
//             <LiveComment
//               channelName={channelName}
//               onChangeCommentCount={setCommentCount}
//             />
//           </div>

//           <div className="me-2">
//             <button onClick={handleLike}>
//               <FaHeart color={likeCount != 0 ? 'red' : "white"} size={32} className="mx-auto" />
//               <p className="text-white text-center text-sm font-bold">
//                 {likeCount || 0}
//               </p>
//             </button>

//             <div className="mt-3">
//               <CgComment color="white" size={32} className="mx-auto" />
//               <p className="text-white text-center text-sm font-bold">
//                 {commentCount || 0}
//               </p>
//             </div>
//           </div>

//         </div>

//         {/* BOTTOM */}
//         <div className="flex items-center justify-between w-full absolute bottom-16 lg:bottom-0 p-3 mt-auto">

//           {/* USER */}
//           <div className="flex p-1 items-center bg-neutral-900 w-72 rounded-full me-3">
//             <img src={authContextState?.user?.profile?.prof_picture || avatar} alt="" className="w-10 h-10 rounded-full me-1" />
//             <div className="ps-2 pe-4">
//               <h3 className="text-white font-bold">{authContextState?.user?.user_surname} {authContextState?.user?.user_name}</h3>
//               <span className="text-xs text-white ">@{authContextState?.user?.user_username}</span>
//             </div>
//           </div>

//           <div className="flex items-center justify-end w-96">
//             <button onClick={handleShowHoteLive} className="me-8">
//               <SiSlideshare color="white" size={32} className="mx-auto" />
//               <p className="text-white text-center text-sm">
//                 <div className="flex items-center">
//                   {requests?.filter(item => !item?.live_request_status)?.length > 0 &&
//                     <div className="ms-auto w-5 top-0 me-1 right-0 text-white bg-red-500 rounded-full">
//                       <span className="m-1">{requests?.filter(item => !item?.live_request_status)?.length < 10 ? requests?.filter(item => item?.live_request_status)?.length : '9+'}</span>
//                     </div>
//                   }
//                   <span>
//                     {i18n.language == 'en'
//                       ? 'Hosts'
//                       : 'Hôtes'
//                     }
//                   </span>
//                 </div>
//               </p>
//             </button>

//             <button onClick={handleShowInviteLive} className="me-8">
//               <MdSupervisorAccount color="white" size={32} className="mx-auto" />
//               <p className="text-white text-center text-sm">
//                 {i18n.language == 'en'
//                   ? 'Invite'
//                   : 'Inviter'
//                 }
//               </p>
//             </button>

//             <button onClick={handleShowShareLive}>
//               <IoIosShareAlt color="white" size={32} className="mx-auto" />
//               <p className="text-white text-center text-sm">
//                 {i18n.language == 'en'
//                   ? 'Share'
//                   : 'Partager'
//                 }
//               </p>
//             </button>
//           </div>

//         </div>

//       </div>
//     </div>

//     {/* INVITE USER */}
//     <LiveInvite
//       live={live}
//       visible={showInviteLive}
//       onChangeVisible={handleShowInviteLive}
//     />

//     {/* SHARE LIVE */}
//     <LiveShare
//       live={live}
//       visible={showShareLive}
//       onChangeVisible={handleShowShareLive}
//     />

//     <LiveHotes
//       live={live}
//       requests={requests}
//       visible={showHoteLive}
//       onChangeVisible={handleShowHoteLive}
//     />

//   </div>
// )
// }

// export default LiveStream