import React, { useEffect, useRef, useState } from "react";
import { useParams, useNavigate, useLocation, Link } from "react-router-dom";
import styles from './LivePublic.module.css'
import {
  AgoraRTCProvider,
  LocalUser,
  LocalVideoTrack,
  RemoteUser,
  RemoteVideoTrack,
  useClientEvent,
  useIsConnected,
  useJoin,
  useLocalCameraTrack,
  useLocalMicrophoneTrack,
  usePublish,
  useRemoteAudioTracks,
  useRemoteUsers,
  useRemoteVideoTracks,
  useRTCClient,
} from "agora-rtc-react";
import AgoraRTC from "agora-rtc-sdk-ng";
import { agoraAppID } from "constants/env";
import { collection, deleteDoc, doc, getDoc, onSnapshot, query, setDoc, updateDoc } from "firebase/firestore";
import firestorekeys from "constants/firestorekeys";
import { firestoreDB } from "services/Firebase/firebase";
import { FaUser } from "react-icons/fa";
import { useAccountReducer } from "hooks/ReducerHooks/ReducerHooks";
import { useAuthReducer } from "hooks/ReducerHooks/ReducerHooks";
import { IoIosCloseCircle } from "react-icons/io";
import { SiSlideshare } from "react-icons/si";
import { IoIosShareAlt } from "react-icons/io";
import { MdSupervisorAccount } from "react-icons/md";
import { FaHeart } from "react-icons/fa";
import { CgComment } from "react-icons/cg";
import { Spinner } from "react-bootstrap";
import avatar from 'assets/images/background_opinion.jpg'
import { useTranslation } from "react-i18next";
import LiveInvite from "../Componants/LiveInvite/LiveInvite";
import LiveShare from "../Componants/LiveShare/LiveShare";
import LiveComment from "../Componants/LiveComment/LiveComment";
import { scheme } from "constants/env";
import { useAgoraContext } from "contexts/AgoraContext";
import { agorakeys } from "constants/agorakeys";
import LiveCommentInput from "../Componants/LiveComment/LiveCommentInput/LiveCommentInput";
import ModelUnauth from "components/ModalUnauth/ModelUnauth";
import background from 'assets/images/bg_clips.jpg'
import Broadcasters from "../LiveJoin/Broadcasters/Broadcasters";

const LivePublic = () => {

  const { t, i18n } = useTranslation()
  const { data: authContextState } = useAuthReducer()
  const navigate = useNavigate();

  const { live_code } = useParams();

  // AGORA CLIENT
  const client = useRef(null)
  const localMicrophoneTrack = useRef(null);
  const localCameraTrack = useRef(null);

  const [uid, setUid] = useState(0)

  const [audiences, setAudiences] = useState([])
  const [remoteUsers, setRemoteUsers] = useState([])
  const [broadcasters, setBroadcasters] = useState([]);
  const [broadcasterCurrent, setBroadcasterCurrent] = useState(null);
  const [request, setRequest] = useState(null);

  // LOCAL USER
  const [live, setLive] = useState(null)
  const [joined, setJoined] = useState(false);
  const [leaved, setLeaved] = useState(false);
  const [finished, setFinished] = useState(false);
  const [micOn, setMic] = useState(true);
  const [cameraOn, setCamera] = useState(true);


  // LIVE STATES
  const [isLiked, setIsLiked] = useState(false)
  const [likeCount, setLikeCount] = useState(0)
  const [commentCount, setCommentCount] = useState(0)
  const [audienceCount, setAudienceCount] = useState(0)

  // MODAL STATE
  const [showInviteLive, setShowInviteLive] = useState(false)
  const [showShareLive, setShowShareLive] = useState(false)
  const [showUnauthModal, setShowUnauthModal] = useState(false)


  const handleShowShareLive = () => setShowShareLive(oldValue => !oldValue)
  const handleShowInviteLive = () => setShowInviteLive(oldValue => !oldValue)
  const handleShowUnauthModal = () => setShowUnauthModal(oldValue => !oldValue)


  // FETCH LIVE
  useEffect(() => {
    const fetchlive = async () => {
      const docRef = doc(firestoreDB, scheme, firestorekeys.lives, firestorekeys.channels, live_code);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        console.log(docSnap.data())
        if (docSnap.data()?.live_finished) {
          setFinished(true)
        } else {
          // Document data found
          const data = docSnap.data()
          setLive(data);
        }
      } else {
        setFinished(true)
      }
    };

    fetchlive();
  }, [live_code]);



  // audience snapshot
  useEffect(() => {
    const q = query(
      collection(
        firestoreDB,
        scheme,
        firestorekeys.lives,
        firestorekeys.channels,
        live_code,
        firestorekeys.audiences
      ),
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
      }));
      setAudiences(data)
    });
    return () => {
      unsubscribe();
    }
  }, []);



  useEffect(() => {
    const q = query(
      collection(
        firestoreDB,
        scheme,
        firestorekeys.lives,
        firestorekeys.channels,
        live_code,
        firestorekeys.audiences
      ),
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
      }));
      setAudienceCount(data?.length)
    });
    return () => {
      unsubscribe();
    }
  }, []);


  // LIKES MANAGEMENT
  useEffect(() => {
    const q = query(
      collection(
        firestoreDB,
        scheme,
        firestorekeys.lives,
        firestorekeys.channels,
        live_code,
        firestorekeys.likes
      ),
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
      }));
      setIsLiked(data?.some(item => item?.id == authContextState?.user?.user_id))
      setLikeCount(data?.length)
    });
    return () => {
      unsubscribe();
    }
  }, []);




  // AGORA INIT
  useEffect(() => {
    const agoraInit = async () => {
      try {
        if (!live || joined) return

        const agoraClient = AgoraRTC.createClient({ mode: "live", codec: "vp8" });
        client.current = agoraClient
        agoraClient.setClientRole(agorakeys.audienceRole)


        // Join channel
        if (!joined) {
          const user = await agoraClient.join(
            agoraAppID,
            live_code,
            live?.live_code || null,
            uid || null,
          )
          setUid(user)
          addAudience(user)
        }

        agoraClient.on("user-joined", (user) => {
          console.log("user-joined:", user);
        })


        agoraClient.on("user-published", async (user, mediaType) => {
          try {
            console.log("user-published:", user.uid);

            // Subscribe to the user
            await agoraClient.subscribe(user, mediaType);

            if (mediaType === "video") {
              const remoteVideoTrack = user.videoTrack;

              console.log('===================', remoteVideoTrack)

              // Create and ensure the video container
              // const containerId = `local-stream`;
              const containerId = `remote-stream-${user.uid}`;
              let videoContainer = document.getElementById(containerId);
              if (!videoContainer) {
                videoContainer = document.createElement("div");
                videoContainer.id = containerId;
                videoContainer.style.width = "100%";
                videoContainer.style.height = "200px";
                document.getElementById("remote-container").appendChild(videoContainer);
              }

              // Play the video track
              remoteVideoTrack.play(containerId);
              console.log("Playing remote video for user:", user.uid);
            }

            if (mediaType === "audio" || mediaType === "all") {
              const remoteAudioTrack = user.audioTrack;
              remoteAudioTrack.play();
            }

            setRemoteUsers((oldUsers) => {
              const exists = oldUsers.some((item) => item.uid === user.uid);
              return exists
                ? oldUsers
                : [...oldUsers, { uid: user.uid, videoTrack: user.videoTrack }];
            });
          } catch (error) {
            console.error("Error handling user-published:", error);
          }
        });


        agoraClient.on("user-unpublished", async (user) => {

          const userIndex = remoteUsers.findIndex((remoteUser) => remoteUser.user.uid === user.uid);
          if (userIndex !== -1) {
            const { track } = remoteUsers[userIndex];

            // Unsubscribe from the user's track if it exists
            if (track) {
              await agoraClient.unsubscribe(track);
              console.log("unsubscribe success:", user.uid);
            }

            // Remove the user from the remoteUsers list
            remoteUsers.splice(userIndex, 1);
          }
        });

        agoraClient.on("user-left", async (user) => {
          console.log("user left:", user);
        });


        //  PUBLISH
        // await client.current.publish([localAudioTrack, localVideoTrack])

      } catch (error) {
        console.error('Error init agora', error);
      }
    }
    agoraInit()

    return () => {
      leaveChannel()
    };
  }, [live]);


  useEffect(() => {
    const getBroadcaster = () => {
      setBroadcasterCurrent(
        audiences?.filter(item =>
          item?.live_is_broadcaster && item?.live_current_speaker
        )[0]
      )

      setBroadcasters(
        audiences?.filter(item =>
          item?.live_is_broadcaster &&
          !item?.live_current_speaker
        )
      )
    }
    getBroadcaster()
  }, [remoteUsers, audiences, request])

  const leaveChannel = async () => {
    try {
      if (!joined) return;

      setJoined(false)

      // Unsubscribe from the user's track if it exists
      remoteUsers.forEach((user) => {
        user?.audioTrack?.stop()
        user?.videoTrack?.stop()
      })

      // Stop device track
      localMicrophoneTrack.current && localMicrophoneTrack.current.stop();
      localCameraTrack.current && localCameraTrack.current.stop();

      // Unpublish
      if (localMicrophoneTrack.current || localCameraTrack.current) {
        await client.current.unpublish([localMicrophoneTrack.current, localCameraTrack.current]);
      }

      // Close device track
      localMicrophoneTrack.current && localMicrophoneTrack.current.close();
      localCameraTrack.current && localCameraTrack.current.close();

      // Leave channel
      await client.current.removeAllListeners();
      await client.current.leave();

      setJoined(false);
      setRemoteUsers([])
      localMicrophoneTrack.current = null;
      localCameraTrack.current = null;

      handleRemoveAudience()

      navigate('/')
    } catch (error) {
      console.error('Error leave Channel:', error);
    }
  };


  const handleComment = async (text) => {
    try {

      const collectionRef = collection(
        firestoreDB,
        scheme,
        firestorekeys.lives,
        firestorekeys.channels,
        live_code,
        firestorekeys.comments
      );

      const refDoc = doc(collectionRef)

      await setDoc(refDoc, {
        text: text,
        timestamp: new Date().getTime(),
        user: {
          user_surname: authContextState?.user?.user_surname,
          user_name: authContextState?.user?.user_name,
          user_username: authContextState?.user?.user_username,
          user_email: authContextState?.user?.user_email,
          profile: {
            prof_picture: authContextState?.user?.profile?.prof_picture
          }
        }
      }).then(() => {
        console.log('Live comments sended.');
      }).catch((error) => {
        console.error('Error send live comment:', error);
      });
    } catch (error) {
      console.error('Error send live comment:', error);
    }
  }



  // UPDATE AUDIENCE

  const addAudience = async (user) => {
    try {
      const collectionRef = collection(
        firestoreDB,
        scheme,
        firestorekeys.lives,
        firestorekeys.channels,
        live_code,
        firestorekeys.audiences
      );
      const docRef = doc(collectionRef, authContextState?.user?.user_id?.toString())
      await setDoc(docRef, {
        ...authContextState?.user,
        live_local_uid: 0,
        live_remote_uid: user,
        live_current_speaker: false,
        live_is_owner: false,
        live_is_broadcaster: false,
        live_on_pause: false,
        live_on_mute: false,
      }).then(() => {
        console.log('Live audience added.');
      }).catch((error) => {
        console.error('Error audience added:', error);
      });
    } catch (error) {
      console.error('Error audience added:', error);
    }
  }


  const handleRemoveAudience = async () => {
    try {
      const collectionRef = collection(
        firestoreDB,
        scheme,
        firestorekeys.lives,
        firestorekeys.channels,
        live_code,
        firestorekeys.audiences
      );

      const refDoc = doc(collectionRef, authContextState?.user?.user_id?.toString())

      await deleteDoc(refDoc).then(() => {
        console.log('Live remove audience.');
      }).catch((error) => {
        console.error('Error remove audience:', error);
      });
    } catch (error) {
      console.error('Error remove audience:', error);
    }
  }


  const handleEndLive = async () => {
    try {
      const refDoc = doc(
        firestoreDB,
        scheme,
        firestorekeys.lives,
        firestorekeys.channels,
        live_code
      )

      await updateDoc(refDoc, { live_finished: true }).then(() => {
        console.log('Live finished.');
        navigate('/')
      }).catch((error) => {
        console.error('Error live finished:', error);
      });
    } catch (error) {
      console.error('Error live finished:', error);
    }
  }

  const handleLike = async () => {
    try {
      const collectionRef = collection(
        firestoreDB,
        scheme,
        firestorekeys.lives,
        firestorekeys.channels,
        live_code,
        firestorekeys.likes
      );

      const refDoc = doc(collectionRef, authContextState?.user?.user_id?.toString())

      if (isLiked) {
        await deleteDoc(refDoc).then(() => {
          console.log('Live like added.');
        }).catch((error) => {
          console.error('Error like added:', error);
        });
      } else {
        await setDoc(refDoc, authContextState?.user).then(() => {
          console.log('Live like added.');
        }).catch((error) => {
          console.error('Error like added:', error);
        });
      }
    } catch (error) {
      console.error('Error like added:', error);
    }
  }


  // if (deviceLoading) {
  //   return (
  //     <div className="flex justify-center items-center h-screen bg-black text-center text-white">
  //       <div>
  //         <Spinner
  //           size="sm"
  //           role="status"
  //           aria-hidden="true"
  //           animation="border"
  //           variant={'secondary'}
  //         // className="me-3"
  //         />
  //         <p>{' '} {i18n.language == 'en' ? 'Loading devices...' : 'Chargement des périphériques audio et vidéo....'}</p>
  //       </div>
  //     </div>
  //   );
  // }

  // if (publishData.isLoading) {
  //   return (
  //     <div className="flex justify-center items-center h-screen bg-black text-center text-white">
  //       <div>
  //         <Spinner
  //           size="sm"
  //           role="status"
  //           aria-hidden="true"
  //           animation="border"
  //           variant={'secondary'}
  //         // className="m-3"
  //         />
  //         <p>{' '}  {i18n.language == 'en' ? 'Loading...' : 'Chargement....'}</p>
  //       </div>
  //     </div>
  //   );
  // }

  if (finished) {
    return (
      <div className="flex justify-center items-center h-screen bg-black text-center text-white">
        <div
          style={{
            backgroundImage: `url(${live?.live_thumbnail || background})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          }}
          className='flex justify-center items-center bg-black w-full md:w-1/2 lg:w-1/2 h-screen'>
          <div className="text-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-12 mx-auto">
              <path strokeLinecap="round" strokeLinejoin="round" d="m15.75 10.5 4.72-4.72a.75.75 0 0 1 1.28.53v11.38a.75.75 0 0 1-1.28.53l-4.72-4.72M12 18.75H4.5a2.25 2.25 0 0 1-2.25-2.25V9m12.841 9.091L16.5 19.5m-1.409-1.409c.407-.407.659-.97.659-1.591v-9a2.25 2.25 0 0 0-2.25-2.25h-9c-.621 0-1.184.252-1.591.659m12.182 12.182L2.909 5.909M1.5 4.5l1.409 1.409" />
            </svg>
            <p className="text-xl text-center font-bold mb-3">
              {i18n.language == 'en'
                ? 'The live is over.'
                : 'Le live est terminé.'
              }
            </p>
            <Link to={'/'} className='px-3 py-2 text-white bg-orange-400 rounded-full'>
              {t('Go_to_home_page')}
            </Link>
          </div>
        </div>
      </div>
    );
  }

  // if (!live || !joined) {
  //   return (
  //     <div className="flex justify-center items-center h-screen bg-black text-center text-white">
  //       <div
  //         style={{
  //           backgroundImage: `url(${live?.live_thumbnail || background})`,
  //           backgroundSize: 'cover',
  //           backgroundPosition: 'center'
  //         }}
  //         className='flex justify-center items-center bg-black w-full md:w-1/2 lg:w-1/2 h-screen'>
  //         <div>
  //           <Spinner
  //             size="lg"
  //             role="status"
  //             aria-hidden="true"
  //             animation="border"
  //             variant={'white'}
  //           />
  //           <p className='mt-2'>{' '} Connexion...</p>
  //         </div>
  //       </div>
  //     </div>
  //     // <div className="flex justify-center items-center h-screen bg-black text-center text-white">
  //     //   <div>
  //     //     <Spinner
  //     //       size="sm"
  //     //       role="status"
  //     //       aria-hidden="true"
  //     //       animation="border"
  //     //       variant={'secondary'}
  //     //     />
  //     //     <p>{' '} Connexion...</p>
  //     //   </div>
  //     // </div>
  //   );
  // }


  return (
    <div className={`${styles.page} h-screen overflow-hidden bg-black`}>
      <div className={`${styles.container} h-full mx-auto overflow-hidden`} >

        {leaved &&
          <div>
            <p className="text-center text-white font-bold">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-12 mx-auto">
                <path strokeLinecap="round" strokeLinejoin="round" d="m15.75 10.5 4.72-4.72a.75.75 0 0 1 1.28.53v11.38a.75.75 0 0 1-1.28.53l-4.72-4.72M12 18.75H4.5a2.25 2.25 0 0 1-2.25-2.25V9m12.841 9.091L16.5 19.5m-1.409-1.409c.407-.407.659-.97.659-1.591v-9a2.25 2.25 0 0 0-2.25-2.25h-9c-.621 0-1.184.252-1.591.659m12.182 12.182L2.909 5.909M1.5 4.5l1.409 1.409" />
              </svg>

              {i18n.language == 'en'
                ? 'The live is over.'
                : 'Le live est terminé.'
              }
            </p>
          </div>
        }

        {/* Local and remote video strean */}
        <div
          style={{
            backgroundImage: `url(${live?.live_thumbnail || background})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          }}
          className='flex justify-between w-full h-full'>

          {/* Local stream */}
          <div
            id={`remote-stream-${broadcasterCurrent?.live_remote_uid}`}
            style={broadcasters?.length == 0 ?
              {
                width: '100%',
                height: '100%',
                backgroundColor: 'black',
              }
              :
              {
                width: '65%',
                height: '60%',
                marginTop: '20%',
                backgroundColor: 'black',
                border: '1px solid #000000'
              }
            }
          />

          {/* Remote user stream */}
          <div id="remote-container"
            style={{
              width: '35%',
              marginTop: '20%',
              height: '60%',
              display: broadcasters?.length == 0 ? 'none' : 'block',
              overflowY: 'auto',
              backgroundColor: 'black'
            }}>
            {broadcasters?.map((user, index) => (
              <div key={index}>
                <Broadcasters
                  user={user}
                  live={live}
                />
              </div>
              // <div
              //   id={`remote-stream-${user?.live_remote_uid}`}
              //   key={index}
              //   className="bg-black w-full h-52"
              //   style={{ border: '1px solid #000000' }} />
            ))}
          </div>
        </div>

        <div>
          {/* HEADER */}
          <div className="w-full top-0 absolute flex justify-between items-center p-3" >

            <div className="flex items-center">
              <div className="bg-red-500 text-white px-3 rounded-full">
                Live
              </div>
              <div className="flex items-center mx-2 px-3 text-white bg-neutral-900 rounded-full">
                <FaUser />
                <span className="ms-1 font-bold">
                  {audienceCount || 0}
                </span>
              </div>
            </div>

            <button onClick={leaveChannel} className="cursor-pointer">
              <IoIosCloseCircle color="white" size={32} />
            </button>

          </div>

          <div className="flex items-end justify-between w-full absolute bottom-16 p-3 mt-auto">

            <div className="w-96 text-white">
              <LiveComment
                channelName={live_code}
                onChangeCommentCount={setCommentCount}
              />
            </div>

            <div className="me-2">
              <button onClick={handleShowUnauthModal}>
                <FaHeart color={isLiked ? 'red' : "white"} size={32} className="mx-auto" />
                <p className="text-white text-center text-sm font-bold">
                  {likeCount || 0}
                </p>
              </button>

              <div className="mt-3">
                <CgComment color="white" size={32} className="mx-auto" />
                <p className="text-white text-center text-sm font-bold">
                  {commentCount || 0}
                </p>
              </div>
            </div>

          </div>

          {/* BOTTOM */}
          <div className="flex items-center justify-between w-full absolute bottom-0 p-3 mt-auto">

            {/* Comments */}
            <div className="w-96 me-3 lg:w-75">
              <div onClick={handleShowUnauthModal} className="flex px-3 text-white py-2 ring-1 ring-white rounded-full cursor-text">
                {i18n.language == 'en' ? 'Comment...' : 'Commentaire...'}
              </div>
            </div>

            <div className="flex items-center justify-end w-96">
              <button onClick={handleShowUnauthModal} className="me-8">
                <SiSlideshare color="white" size={32} className="mx-auto" />
                <p className="text-white text-center text-sm">
                  {i18n.language == 'en'
                    ? 'Hosts'
                    : 'Hôtes'
                  }
                </p>
              </button>

              <button onClick={handleShowUnauthModal} className="me-8">
                <MdSupervisorAccount color="white" size={32} className="mx-auto" />
                <p className="text-white text-center text-sm">
                  {i18n.language == 'en'
                    ? 'Invite'
                    : 'Inviter'
                  }
                </p>
              </button>

              <button onClick={handleShowUnauthModal}>
                <IoIosShareAlt color="white" size={32} className="mx-auto" />
                <p className="text-white text-center text-sm">
                  {i18n.language == 'en'
                    ? 'Share'
                    : 'Partager'
                  }
                </p>
              </button>
            </div>

          </div>

        </div>

      </div>


      {/* INVITE USER */}
      <LiveInvite
        live={live}
        visible={showInviteLive}
        onChangeVisible={handleShowInviteLive}
      />

      {/* SHARE LIVE */}
      <LiveShare
        live={live}
        visible={showShareLive}
        onChangeVisible={handleShowShareLive}
      />

      {/* RENDER MODAL UNAUTH */}
      <ModelUnauth show={showUnauthModal} setShow={setShowUnauthModal} />

    </div>
  )

  return (
    <div className={`${styles.page} h-screen overflow-hidden bg-black`}>
      <div className={`${styles.container} w-2/3 h-full mx-auto overflow-hidden`}>

        <div className="flex items-center justify-center h-screen bg-black"
        >

          {/* {leaved &&
            <div>
              <p className="text-center text-white font-bold">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-12 mx-auto">
                  <path strokeLinecap="round" strokeLinejoin="round" d="m15.75 10.5 4.72-4.72a.75.75 0 0 1 1.28.53v11.38a.75.75 0 0 1-1.28.53l-4.72-4.72M12 18.75H4.5a2.25 2.25 0 0 1-2.25-2.25V9m12.841 9.091L16.5 19.5m-1.409-1.409c.407-.407.659-.97.659-1.591v-9a2.25 2.25 0 0 0-2.25-2.25h-9c-.621 0-1.184.252-1.591.659m12.182 12.182L2.909 5.909M1.5 4.5l1.409 1.409" />
                </svg>

                {i18n.language == 'en'
                  ? 'The live is over.'
                  : 'Le live est terminé.'
                }
              </p>
            </div>
          } */}

          {/* Local and remote video strean */}
          <div
            style={{
              backgroundImage: `url(${live?.live_thumbnail || background})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center'
            }}
            className='flex justify-between w-full h-full'>

            {/* Local stream */}
            <div
              id={`remote-stream-${broadcasterCurrent?.live_remote_uid}`}
              style={broadcasters?.length == 0 ?
                {
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'black',
                }
                :
                {
                  width: '65%',
                  height: '60%',
                  marginTop: '20%',
                  backgroundColor: 'black',
                  border: '1px solid #000000'
                }
              }
            />

            {/* Remote user stream */}
            <div id="remote-container"
              style={{
                width: '35%',
                marginTop: '20%',
                height: '60%',
                display: broadcasters?.length == 0 ? 'none' : 'block',
                overflowY: 'auto',
                backgroundColor: 'black'
              }}>
              {broadcasters?.map((user, index) => (
                <div key={index}>
                  <Broadcasters
                    user={user}
                    live={live}
                  />
                </div>
                // <div
                //   id={`remote-stream-${user?.live_remote_uid}`}
                //   key={index}
                //   className="bg-black w-full h-52"
                //   style={{ border: '1px solid #000000' }} />
              ))}
            </div>
          </div>
        </div>

        <div>
          {/* HEADER */}
          <div className="w-full top-0 absolute flex justify-between items-center p-3" >

            <div className="flex items-center">
              <div className="bg-red-500 text-white px-3 rounded-full">
                Live
              </div>
              <div className="flex items-center mx-2 px-3 text-white bg-neutral-900 rounded-full">
                <FaUser />
                <span className="ms-1 font-bold">
                  {audienceCount || 0}
                </span>
              </div>
            </div>

            <button onClick={leaveChannel} className="cursor-pointer">
              <IoIosCloseCircle color="white" size={32} />
            </button>

          </div>

          <div className="flex items-end justify-between w-full absolute bottom-16 p-3 mt-auto">

            <div className="w-96 text-white">
              <LiveComment
                channelName={live_code}
                onChangeCommentCount={setCommentCount}
              />
            </div>

            <div className="me-2">
              <button onClick={handleShowUnauthModal}>
                <FaHeart color={isLiked ? 'red' : "white"} size={32} className="mx-auto" />
                <p className="text-white text-center text-sm font-bold">
                  {likeCount || 0}
                </p>
              </button>

              <div className="mt-3">
                <CgComment color="white" size={32} className="mx-auto" />
                <p className="text-white text-center text-sm font-bold">
                  {commentCount || 0}
                </p>
              </div>
            </div>

          </div>

          {/* BOTTOM */}
          <div className="flex items-center justify-between w-full absolute bottom-0 p-3 mt-auto">

            {/* Comments */}
            <div className="w-96 me-3 lg:w-75">
              <div onClick={handleShowUnauthModal} className="flex px-3 text-white py-2 ring-1 ring-white rounded-full cursor-text">
                {i18n.language == 'en' ? 'Comment...' : 'Commentaire...'}
              </div>
            </div>

            <div className="flex items-center justify-end w-96">
              <button onClick={handleShowUnauthModal} className="me-8">
                <SiSlideshare color="white" size={32} className="mx-auto" />
                <p className="text-white text-center text-sm">
                  {i18n.language == 'en'
                    ? 'Hosts'
                    : 'Hôtes'
                  }
                </p>
              </button>

              <button onClick={handleShowUnauthModal} className="me-8">
                <MdSupervisorAccount color="white" size={32} className="mx-auto" />
                <p className="text-white text-center text-sm">
                  {i18n.language == 'en'
                    ? 'Invite'
                    : 'Inviter'
                  }
                </p>
              </button>

              <button onClick={handleShowUnauthModal}>
                <IoIosShareAlt color="white" size={32} className="mx-auto" />
                <p className="text-white text-center text-sm">
                  {i18n.language == 'en'
                    ? 'Share'
                    : 'Partager'
                  }
                </p>
              </button>
            </div>

          </div>

        </div>
      </div>

      {/* INVITE USER */}
      <LiveInvite
        live={live}
        visible={showInviteLive}
        onChangeVisible={handleShowInviteLive}
      />

      {/* SHARE LIVE */}
      <LiveShare
        live={live}
        visible={showShareLive}
        onChangeVisible={handleShowShareLive}
      />

      {/* RENDER MODAL UNAUTH */}
      <ModelUnauth show={showUnauthModal} setShow={setShowUnauthModal} />

    </div>
  )
}

export default LivePublic
