import React, { memo } from 'react'
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AiFillLike, AiLike, AiOutlineLike, AiOutlineClose } from 'react-icons/ai'
import { useTranslation } from 'react-i18next';
import { IoIosCloseCircle } from "react-icons/io";
import { color } from 'hooks/Utils/color';
import { Link } from 'react-router-dom';

const ModelUnauth = ({
    show = false,
    setShow = () => null
}) => {

    const { t } = useTranslation()

    return (
        <Modal show={show} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className="show-grid">
                <Container>

                    <button onClick={() => setShow(false)} style={{ position: 'absolute', right: 15 }}>
                        <IoIosCloseCircle size={32} color={color.primary} />
                    </button>

                    <div style={{ textAlign: 'center', paddingTop: '8%', paddingBottom: '8%' }}>
                        <img src={require('../../assets/app/icon.png')} width={100} alt='' className='mx-auto' />
                        <h1 className='font-bold mb-2 text-xl'>{t('app_name')}</h1>
                        <h5 className='mb-3' style={{ textAlign: 'center' }}>
                            {t('login_now_to_interact_with_thousands_of_people')}
                        </h5>
                        <Link to={'/login'} className='px-3 py-2 text-white bg-orange-400 rounded-full'>
                            {t('login')}
                        </Link>
                    </div>

                </Container>
            </Modal.Body>
        </Modal>
    )
}

export default memo(ModelUnauth);