import React, { useEffect, useState } from 'react'
import { collection, deleteDoc, doc, getDoc, onSnapshot, query, setDoc, updateDoc } from "firebase/firestore";
import { firestoreDB } from 'services/Firebase/firebase';
import { scheme } from 'constants/env';
import firestorekeys from 'constants/firestorekeys';
import { Link, useParams } from 'react-router-dom';
import background from 'assets/images/bg_clips.jpg'
import { useTranslation } from 'react-i18next';
import { MdAdsClick, MdLiveTv } from 'react-icons/md';
import avatar from 'assets/images/background_opinion.jpg'
import { Spinner } from 'react-bootstrap';

const LiveSearch = () => {

    const { t, i18n } = useTranslation();
    const { live_code } = useParams();
    const [live, setLive] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [isEmpty, setIsEmpty] = useState(false)

    useEffect(() => {
        const fetchlive = async () => {
            const docRef = doc(firestoreDB, scheme, firestorekeys.lives, firestorekeys.channels, live_code);
            const docSnap = await getDoc(docRef);
            console.log('Live data:', docSnap.data())
            if (
                docSnap.exists() &&
                !docSnap.data()?.live_finished &&
                docSnap.data()?.live_visibility == firestorekeys.generalPublic
            ) {
                console.log('Live data:', docSnap.data())
                setIsEmpty(false)
                setIsLoading(false)
                setLive(docSnap.data());
            } else {
                setIsEmpty(true)
                setIsLoading(false)
            }
        };

        fetchlive();
    }, [live_code]);



    if (isLoading) {
        return (
            <div className="flex justify-center items-center h-screen bg-black text-center text-white">
                <div
                    style={{
                        backgroundImage: `url(${live?.live_thumbnail || background})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center'
                    }}
                    className='flex justify-center items-center bg-black w-full md:w-1/2 lg:w-1/2 h-screen'>
                    <div>
                        <Spinner
                            size="lg"
                            role="status"
                            aria-hidden="true"
                            animation="border"
                            variant={'white'}
                        />
                        <p className='mt-2'>{' '} {i18n.language == 'en' ? 'Loading...' : 'Chargement...'}</p>
                    </div>
                </div>
            </div>
        );
    }

    if (isEmpty) {
        return (
            <div className="flex justify-center items-center h-screen bg-black text-center text-white">
                <div
                    style={{
                        backgroundImage: `url(${live?.live_thumbnail || background})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center'
                    }}
                    className='flex justify-center items-center bg-black w-full md:w-1/2 lg:w-1/2 h-screen'>
                    <div>
                        <MdLiveTv
                            color='white'
                            size={68}
                            className='mx-auto'
                        />
                        <p className='text-white my-3 text-center'>
                            {t('live_unavailable')}
                        </p>

                        <Link to={'/'} className='px-3 py-2 text-white bg-orange-400 rounded-full'>
                            {t('Go_to_home_page')}
                        </Link>
                    </div>
                </div>

            </div>
        )
    }



    return (
        <div
            className='flex h-screen justify-center items-center bg-black'>
            <div className='bg-black w-full md:w-1/2 lg:w-1/2 h-screen'>
                <Link
                    to={`/live/${live?.live_channel_name}/public`}
                    state={{
                        live_data: live
                    }}
                    className='w-full'>
                    <div
                        style={{
                            backgroundImage: `url(${live?.live_thumbnail || background})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center'
                        }}
                        className='flex items-end justify-center h-screen bg-black opacity-95 border-b-4 border-b-gray-500'>
                        <div className='w-full px-3 mb-20 md:mb-10'>
                            <div className='w-full mb-44'>
                                <MdAdsClick
                                    color='white'
                                    size={60}
                                    className='mx-auto'
                                />
                                <p className='text-white my-3 text-center font-bold'>
                                    {i18n.language == 'en'
                                        ? "Click to join the live"
                                        : "Cliquez pour rejoindre le live"
                                    }
                                </p>
                            </div>
                            <div className='w-full'>
                                <p className='text-white mb-3'>
                                    {live?.live_description}
                                </p>
                                <div className="flex p-1 items-center w-72 rounded-full me-3 border border-neutral-700" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                                    <img src={live?.user?.profile?.prof_picture || avatar} alt="" className="w-10 h-10 rounded-full me-1" />
                                    <div className="ps-2 pe-4">
                                        <h3 className="text-white font-bold">{live?.user?.user_surname} {live?.user?.user_name}</h3>
                                        <span className="text-xs text-white ">@{live?.user?.user_username}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        </div>
    )
}

export default LiveSearch